import {authAxios as axios} from "../../request";
import {loginSendOtpUrl, loginSubmitLoginUrl, resendOtpUrl} from "./api";
import {notification} from "../../components/Elements";
import {showLoader, hideLoader} from "../../actions/loader";

export const loginSendOtpFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(loginSendOtpUrl(), valData);
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
}

export const loginSubmitLoginFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(loginSubmitLoginUrl(), valData);
    dispatch(hideLoader())
    if (!data.error) {
        /* notification.success({
             message: data.message || 'Success'
         })*/
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
}

export const resendOtpFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(resendOtpUrl(), valData);
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
}
