import React from "react";
import {NavLink} from "react-router-dom"; // Import NavLink
import "../assets/css/style.css";
import "../assets/css/slick.css";
import "../assets/css/slick-theme.css";

const Header = (props) => {
    let {currentUser} = props;
    return (
        <>
            <header>
                {/* Fixed navbar */}
                <nav className="navbar navbar-expand-md navbar-dark fixed-top">
                    <div className="container">
                        <a className="navbar-brand" href="home">
                            <img src="../images/logo.png" alt="logo" width="140"/>
                        </a>
                        <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"/>
                        </button>
                        <div className="navbar-collapse collapse" id="navbarCollapse">
                            <ul className="navbar-nav mx-auto mb-2 mb-md-0">
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/home" href="#" activeClassName="active"
                                             exact>Home</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/agents" activeClassName="active">Agents</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/about-us" activeClassName="active">About
                                        Us</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/events" activeClassName="active">Events</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/contact-us" activeClassName="active">Contact
                                        Us</NavLink>
                                </li>
                            </ul>
                            <div className="header-btn-group">
                                <a href="#" className="btn switch-portal">Switch to <b>UnizPortal</b></a>
                                <div className="flex-btns">
                                    {currentUser && currentUser.name ?
                                        <ul className="navbar-nav mx-auto mb-2 mb-md-0 mt-2">
                                            <li className="nav-item">
                                                <NavLink className="nav-link" activeClassName="active"
                                                         to="/dashboard">Welcome <b>{currentUser.name}</b></NavLink>
                                            </li>
                                        </ul> :
                                        <ul className="navbar-nav mx-auto mb-2 mb-md-0 mt-2">
                                            <li className="nav-item">
                                                <NavLink className="nav-link" activeClassName="active"
                                                         to="/login">Login</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" activeClassName="active"
                                                         to="/register">Register</NavLink>
                                            </li>
                                        </ul>}
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
}

export default Header;
