import React from "react";
import {Outlet, useLocation} from "react-router-dom";
import Footer from "./footer";
import LoginHeader from "./loginHeader";

const Layout = (props) => {
    return (
        <>
            <LoginHeader  {...props}/>
            <main className="flex-shrink-0">
                <Outlet/>
            </main>
            <Footer/>
        </>
    );
};

export default Layout;
