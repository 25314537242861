import React from "react";
import {Outlet, useLocation} from "react-router-dom";
import Header from "./header";
import Footer from "./footer";

const FrontLayout = (props) => {
    return (
        <>
            <Header {...props}/>
            <main className="flex-shrink-0">
                <Outlet/>
            </main>
            <Footer/>
        </>
    )
}

export default FrontLayout;
