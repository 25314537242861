import Home from "../web/home";
import Agents from "../web/agents";
import AboutUs from "../web/aboutUs";
import Events from "../web/events";
import ContactUs from "../web/contactUs";
import Dashboard from "../web/dashboard";
import Login from "../containers/authications/login";
import Register from "../containers/authications/register";
import ForgotPassword from "../containers/authications/forgotPassword";
import FlightBooking from "../containers/flightBooking";
import FindFlight from "../containers/findFlight";
import Checkout from "../containers/checkout";
import TicketResponse from "../containers/ticketResponse";


let frontMenu = [
    {
        name: "Home",
        title: "Home",
        component: Home,
        path: "/"
    },
    {
        name: "About Us",
        title: "About Us",
        component: AboutUs,
        path: "/about-us"
    },
    {
        name: "Events",
        title: "Events",
        component: Events,
        path: "/events"
    },
    {
        name: "Contact Us",
        title: "Contact Us",
        component: ContactUs,
        path: "/contact-us"
    },
    {
        name: "Login",
        title: "Login",
        component: Login,
        path: "/login"
    },
    {
        name: "Register",
        title: "Register",
        component: Register,
        path: "/register"
    },
    {
        name: "Forgot Password",
        title: "Forgot Password",
        component: ForgotPassword,
        path: "/forgot-password"
    },
]
let menu = [
    {
        name: "Dashboard",
        title: "Dashboard",
        component: Dashboard,
        path: "/dashboard"
    },
    {
        name: "Agents",
        title: "Agents",
        component: Agents,
        path: "/agents"
    },
    {
        name: "Checkout",
        title: "Checkout",
        component: Checkout,
        path: "/checkout"
    },
    {
        name: "Find Flight",
        title: "Find Flight",
        component: FindFlight,
        path: "/find-flight"
    },
    {
        name: "Find Booking",
        title: "Find Booking",
        component: FlightBooking,
        path: "/find-booking"
    },
    {
        name: "Ticket Response",
        title: "Ticket Response",
        component: TicketResponse,
        path: "/response/:id"
    }
]

export {frontMenu, menu}
