import React from "react";
import {NavLink} from "react-router-dom";
import "../assets/assets/css/style.css";
import "../assets/assets/css/slick.css";
import "../assets/css/slick-theme.css";
import commonFxns from "../components/commonFxns";

const LoginHeader = (props) => {
    let {currentUser} = props;
    return (
        <header className="dashboard">
            {/* Fixed navbar */}
            <nav className="navbar navbar-expand-md navbar-dark fixed-top">
                <div className="container">
                    <NavLink className="navbar-brand" to="/">
                        <img src="..//images2/x-travel-landscape.png" alt="logo" height="90"/>
                    </NavLink>
                    <button
                        className="navbar-toggler collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse"
                        aria-controls="navbarCollapse"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div className="navbar-collapse collapse" id="navbarCollapse">
                        <ul className="navbar-nav ms-auto mb-2 mb-md-0 align-items-center">
                            <li className="nav-item">
                                <span>
                                    <div className="pic">
                                        <img
                                            src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                            alt="User"
                                        />
                                    </div>
                                    Hello {currentUser && currentUser.name ? currentUser.name : ""}
                                </span>
                            </li>
                            <li className="nav-item">
                                <span className="d-flex align-items-center gap-2">
                                    <img src="/images2/wallet-color-icon.png" width="25" alt="wallet-icon"/>
                                    My Wallet: INR 72,365.51
                                    <button className="btn p-0 border-0">
                                        <img src="/images2/add-icon.png" alt="add-icon"/>
                                    </button>
                                </span>
                            </li>
                            <li className="nav-item">
                                <span className="d-flex align-items-center gap-2">
                                    <img src="/images2/wallet-icon.png" alt="wallet-icon" width="25"/>
                                    My Cash: 100.00
                                </span>
                            </li>
                            <li className="nav-item dropdown">
                                <a
                                    className="nav-link d-flex align-items-center gap-2 dropdown-toggle"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <img src="/images2/manager-icon.png" alt="manager-icon" width="25"/> Manager
                                </a>
                                <ul className="dropdown-menu manage-box">
                                    <div className="d-flex gap-3"
                                         style={{borderBottom: "1px solid #d0d8e0", paddingBottom: "10px"}}>
                                        <span className="pic m-0">
                                            <img
                                                src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                                alt="Manager"
                                            />
                                        </span>
                                        <h5 className="m-0">
                                            Harpreet Kaur
                                            <span className="d-block mt-1">+91 98888 98888</span>
                                        </h5>
                                    </div>
                                    <li>
                                        <NavLink className="dropdown-item red" to="#">
                                            Schedule a Call with Expert
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="dropdown-item" to="#">
                                            Raise a Complaint
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="dropdown-item" to="#">
                                            Request a Portal Training
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/dashboard">
                                    Dashboard
                                </NavLink>
                            </li>
                            <li className="nav-item cursor-pointer" onClick={commonFxns.logout}>
                                Log Out
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default LoginHeader;
